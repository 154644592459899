import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueGtag from "vue-gtag";

//Vue.prototype.$http = axios;

const token = localStorage.getItem('fmrtoken');
const admintoken = localStorage.getItem('fmrsuperadmintoken');
//axios.defaults.baseURL = 'http://localhost:5000/api/';
axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL
if (token) {
    axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
}
 
if (admintoken) {
    axios.defaults.headers.common['authorization'] = `Bearer ${admintoken}`;
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
}

const app = createApp(App).use(store).use(router);

app.use(VueGtag, {
    appName: 'Faslearn Application',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: "G-Q67EZD2XE6",
    },
}, router);

app.mount('#app')
