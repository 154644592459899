import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/site/Home.vue'
import store from '../store'

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
},
{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/site/About.vue')
},
{
    path: '/fast-her',
    name: 'GirlChild',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/site/GirlChild.vue')
},
{
    path: '/fast-her/about',
    name: 'AboutGirlChild',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/site/AboutGirlChild.vue')
},
{
    path: '/affiliate',
    name: 'Affiliate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "affiliate" */ '../views/site/Affiliate.vue')
},
{
    path: '/admission',
    name: 'Admission',
    component: () =>
        import( /* webpackChunkName: "admission" */ '../views/site/Admission.vue'),
    meta: {
        requiresAuth: true
    }
},
{
    path: '/terms',
    name: 'terms',
    component: () =>
        import( /* webpackChunkName: "terms" */ '../views/site/Terms.vue')
},
{
    path: '/chat',
    name: 'chat',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/site/Chat.vue'),

},
{
    path: '/privacy',
    name: 'Privacy',
    component: () =>
        import( /* webpackChunkName: "privacy" */ '../views/site/Privacy.vue')
},
{
    path: '/webinar',
    name: 'Webinar',
    component: () =>
        import( /* webpackChunkName: "Webinar" */ '../views/site/Webinar.vue')
},
{
    path: '/search',
    name: 'Search',
    component: () =>
        import( /* webpackChunkName: "Search" */ '../views/site/Search.vue')
},
{
    path: '/passwordRequest',
    name: 'PasswordRequest',
    component: () =>
        import( /* webpackChunkName: "PasswordRequest" */ '../views/site/PasswordRequest.vue')
},
{
    path: '/ChangePassword/:userId/:token',
    name: 'ChangePassword',
    component: () =>
        import( /* webpackChunkName: "ChangePassword" */ '../views/site/ChangePassword.vue')
},
{
    path: '/VerifyEmail/:userId/:verifyId',
    name: 'VerifyEmail',
    component: () =>
        import( /* webpackChunkName: "VerifyEmail" */ '../views/site/VerifyEmail.vue')
},
{
    path: '/allcourses/:cat',
    name: 'Allcourses',
    component: () =>
        import( /* webpackChunkName: "allcourses" */ '../views/site/Allcourses.vue')
},
{
    path: '/coursedetail/:id',
    name: 'Coursedetail',
    component: () =>
        import( /* webpackChunkName: "Coursedetail" */ '../views/site/Coursedetail.vue')
},
{
    path: '/pricing',
    name: 'Pricing',
    component: () =>
        import( /* webpackChunkName: "Pricing" */ '../views/site/Pricing.vue')
},
{
    path: '/teacher/:id',
    name: 'Teacher',
    component: () =>
        import( /* webpackChunkName: "Teacher" */ '../views/site/Teacher.vue')
},
{
    path: '/cart',
    name: 'Cart',
    component: () =>
        import( /* webpackChunkName: "Cart" */ '../views/site/Cart.vue')
},
{
    path: '/cart2',
    name: 'Cart2',
    component: () =>
        import( /* webpackChunkName: "Cart2" */ '../views/site/Cart2.vue')
},
{
    path: '/cart3',
    name: 'Cart3',
    component: () =>
        import( /* webpackChunkName: "Cart3" */ '../views/site/Cart3.vue')
},
{
    path: '/contact',
    name: 'Contact',
    component: () =>
        import( /* webpackChunkName: "Contact" */ '../views/site/Contact.vue')
},
{
    path: '/allteachers/:id',
    name: 'Allteachers',
    component: () =>
        import( /* webpackChunkName: "Allteachers" */ '../views/site/Allteachers.vue')
},
{
    path: '/allinstitutes/:type',
    name: 'Allinstitudes',
    component: () =>
        import( /* webpackChunkName: "Allinstitudes" */ '../views/site/Allinstitudes.vue')
},
{
    path: '/institute/:id',
    name: 'Institude',
    component: () =>
        import( /* webpackChunkName: "institude" */ '../views/site/Institude.vue')
},
{
    path: '/faq',
    name: 'Faq',
    component: () =>
        import( /* webpackChunkName: "Faq" */ '../views/site/Faq.vue')
},
{
    path: '/help',
    name: 'Help',
    component: () =>
        import( /* webpackChunkName: "Help" */ '../views/site/Help.vue')
},
{
    path: '/login',
    name: 'Login',
    component: () =>
        import( /* webpackChunkName: "Login" */ '../views/site/Login.vue'),
    meta: {
        requiresGuest: true
    }
},
{
    path: '/register',
    name: 'Register',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/site/Register.vue'),
    meta: {
        requiresGuest: true
    }
},

{
    path: '/terms',
    name: 'terms',
    component: () =>
        import( /* webpackChunkName: "terms" */ '../views/site/Terms.vue')
}, {
    path: '/privacy',
    name: 'Privacy',
    component: () =>
        import( /* webpackChunkName: "privacy" */ '../views/site/Privacy.vue')
}, {
    path: '/passwordRequest',
    name: 'PasswordRequest',
    component: () =>
        import( /* webpackChunkName: "PasswordRequest" */ '../views/site/PasswordRequest.vue')
}, {
    path: '/ChangePassword/:userId/:token',
    name: 'ChangePassword',
    component: () =>
        import( /* webpackChunkName: "ChangePassword" */ '../views/site/ChangePassword.vue')
}, {
    path: '/allcourses/:cat',
    name: 'Allcourses',
    component: () =>
        import( /* webpackChunkName: "allcourses" */ '../views/site/Allcourses.vue')
}, {
    path: '/coursedetail/:id',
    name: 'Coursedetail',
    component: () =>
        import( /* webpackChunkName: "Coursedetail" */ '../views/site/Coursedetail.vue')
}, {
    path: '/pricing',
    name: 'Pricing',
    component: () =>
        import( /* webpackChunkName: "Pricing" */ '../views/site/Pricing.vue')
}, {
    path: '/teacher/:id',
    name: 'Teacher',
    component: () =>
        import( /* webpackChunkName: "Teacher" */ '../views/site/Teacher.vue')
}, {
    path: '/cart',
    name: 'Cart',
    component: () =>
        import( /* webpackChunkName: "Cart" */ '../views/site/Cart.vue')
}, {
    path: '/cart2',
    name: 'Cart2',
    component: () =>
        import( /* webpackChunkName: "Cart2" */ '../views/site/Cart2.vue')
}, {
    path: '/cart3',
    name: 'Cart3',
    component: () =>
        import( /* webpackChunkName: "Cart3" */ '../views/site/Cart3.vue')
}, {
    path: '/contact',
    name: 'Contact',
    component: () =>
        import( /* webpackChunkName: "Contact" */ '../views/site/Contact.vue')
}, {
    path: '/allteachers/:id',
    name: 'Allteachers',
    component: () =>
        import( /* webpackChunkName: "Allteachers" */ '../views/site/Allteachers.vue')
}, {
    path: '/allinstitutes/:type',
    name: 'AlÏlinstitudes',
    component: () =>
        import( /* webpackChunkName: "Allinstitudes" */ '../views/site/Allinstitudes.vue')
}, {
    path: '/:handle',
    name: 'Institude',
    component: () =>
        import( /* webpackChunkName: "institude" */ '../views/site/Institude.vue')
}, {
    path: '/blog',
    name: 'Blog',
    component: () =>
        import( /* webpackChunkName: "Blog" */ '../views/site/Blog.vue')
}, {
    path: '/blogpost/:id',
    name: 'Blogpost',
    component: () =>
        import( /* webpackChunkName: "Blogpost" */ '../views/site/Blogpost.vue')
}, {
    path: '/faq',
    name: 'Faq',
    component: () =>
        import( /* webpackChunkName: "Faq" */ '../views/site/Faq.vue')
}, {
    path: '/help',
    name: 'Help',
    component: () =>
        import( /* webpackChunkName: "Help" */ '../views/site/Help.vue')
}, {
    path: '/login',
    name: 'Login',
    component: () =>
        import( /* webpackChunkName: "Login" */ '../views/site/Login.vue'),
    meta: {
        requiresGuest: true
    }
}, {
    path: '/register',
    name: 'Register',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/site/Register.vue'),
    meta: {
        requiresGuest: true
    }
},

// dashboard routes starts here
{
    path: '/dashboard',
    name: 'Dashboard',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/Home.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dteachers/:id',
    name: 'Dteachers',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/Teachers.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dapllyteacher',
    name: 'Dapllyteacher',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/Apllyteacher.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dcategories',
    name: 'Dcategories',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/Categories.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dcheck',
    name: 'Dcheck',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/Check.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dcontact',
    name: 'Dcontact',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/Contact.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dcoursedetail/:id',
    name: 'Dcoursedetail',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/Coursedetail.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dpackage/:id',
    name: 'Dpackage',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/Package.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dallpackages',
    name: 'Dallpackages',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/Allpackages.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dtestrules/:id',
    name: 'Dtestrules',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/TestRules.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dtestroom/:id',
    name: 'Dtestroom',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/TestRoom.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/DcourseCertificate/:courseId/:testId',
    name: 'DcourseCertificate',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/Certificate.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dexplore',
    name: 'Dexplore',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/Explore.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/DregisterTeacher',
    name: 'DregisterTeacher',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/RegisterTeacher.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/DregisterInstitude',
    name: 'DregisterInstitude',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/RegisterInstitude.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/DregisterMembership',
    name: 'DregisterMembership',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/RegisterMembership.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dmembership/:id',
    name: 'Dmembership',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/MemberShip.vue'),
    meta: {
        requiresAuth: true
    }
},
{
    path: '/Dbook/:id',
    name: 'Dbook',
    component: () =>
        import ( /* webpackChunkName: "Register" */ '../views/dashboard/Book.vue'),
    meta: {
        requiresAuth: true
    }
}, 
{
    path: '/Dbooks',
    name: 'Dbooks',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Books.vue'),
    meta: {
        requiresAuth: true
    }
},
{
    path: '/Deditbook/:id',
    name: 'Deditbook',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/EditBook.vue'),
    meta: {
        requiresAuth: true
    }
},
{
    path: '/:handle/dashboard',
    name: 'Dinstitute',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/Institude.vue'),
    meta: {
        requiresAuth: true
    }
},
    {
        path: '/outlet/:userId',
        name: 'Doutletdetails',
        component: () =>
            import( /* webpackChunkName: "Register" */ '../views/dashboard/Outlet.vue'),
        meta: {
            requiresAuth: true
        }
    },
{
    path: '/Dallinstitutions/:type',
    name: 'Dallinstitutions',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/Allinstitutions.vue'),
    meta: {
        requiresAuth: true
    }
},
    // {
    //     path: '/Dalloutlets/:type',
    //     name: 'Dalloutlets',
    //     component: () =>
    //         import( /* webpackChunkName: "Register" */ '../views/dashboard/AllOutlets.vue'),
    //     meta: {
    //         requiresAuth: true
    //     }
    // },
// {
//     path: '/Doutlet/:type',
//     name: 'Doutlet',
//     component: () =>
//         import( /* webpackChunkName: "Register" */ '../views/dashboard/Outlet.vue'),
//     meta: {
//         requiresAuth: true
//     }
// },
{
    path: '/Dadmission/:id',
    name: 'Dadmission',
    component: () =>
        import( /* webpackChunkName: "admission" */ '../views/dashboard/Admission.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Demployment/:id',
    name: 'Demployment',
    component: () =>
        import( /* webpackChunkName: "admission" */ '../views/dashboard/Employment.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dsettings',
    name: 'Dsettings',
    component: () =>
        import( /* webpackChunkName: "admission" */ '../views/dashboard/Settings.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dfeedback',
    name: 'Dfeedback',
    component: () =>
        import( /* webpackChunkName: "admission" */ '../views/dashboard/Feedback.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dhelp',
    name: 'Dhelp',
    component: () =>
        import( /* webpackChunkName: "admission" */ '../views/dashboard/Help.vue'),
    meta: {
        requiresAuth: true
    }
},

    //person dashboard routes
    {
        path: '/Dnewbook',
        name: 'Dnewbook',
        component: () =>
            import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Newbook.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Dnewcourse',
        name: 'Dnewcourse',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Newcourse.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Dmymemberships',
        name: 'Dmymemberships',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/MyMemberShips.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Deditcourse/:id',
        name: 'Deditcourse',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Editcourse.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Dnewcoursecontent/:id',
        name: 'Dnewcoursecontent',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Newcoursecontent.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Dnewcoursecontent2/:id',
        name: 'Dnewcoursecontent2',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Newcoursecontent2.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/DnewcourseAffiliation/:id',
        name: 'DnewcourseAffiliation',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/NewcourseAffiliation.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/DnewcourseTest/:id',
        name: 'DnewcourseTest',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/NewcourseTest.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/DnewcourseCoupons/:id',
        name: 'DnewcourseCoupons',
        component: () =>
            import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/NewcourseCoupons.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/DlectureRoom/:id',
        name: 'DlectureRoom',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/LectureRoom.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Dreviews',
        name: 'Dreviews',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Reviews.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Dcourses',
        name: 'Dcourses',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Courses.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Dhome',
        name: 'Dhome',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Home.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Dcertificate',
        name: 'Dcertificate',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Certificate.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Dnotification',
        name: 'Dnotification',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Notification.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Dpayout',
        name: 'Dpayout',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Payout.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Dprofile/:id',
        name: 'Dprofile',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Profile.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Dallcourses/:cat',
        name: 'Dallcourses',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/Allcourses.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Dstatements',
        name: 'Dstatements',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Statements.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Dverification',
        name: 'Dverification',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Verification.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/DmanageAdmission/:id',
        name: 'DmanageAdmission',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/ManageAdmission.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/DmanageEmployment/:id',
        name: 'DmanageEmployment',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/ManageEmployment.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Dearning',
        name: 'Dearning',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Earning.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Danalysis',
        name: 'Danalysis',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Analysis.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/Dmessage/:id',
        name: 'Dmessage',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Message.vue'),
        meta: {
            requiresAuth: true
        }
    },

{
    path: '/Dmymemberships',
    name: 'Dmymemberships',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/MyMemberShips.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Deditcourse/:id',
    name: 'Deditcourse',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Editcourse.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dnewcoursecontent/:id',
    name: 'Dnewcoursecontent',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Newcoursecontent.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dnewcoursecontent2/:id',
    name: 'Dnewcoursecontent2',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Newcoursecontent2.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/DnewcourseAffiliation/:id',
    name: 'DnewcourseAffiliation',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/NewcourseAffiliation.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/DnewcourseTest/:id',
    name: 'DnewcourseTest',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/NewcourseTest.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/DlectureRoom/:id',
    name: 'DlectureRoom',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/LectureRoom.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dreviews',
    name: 'Dreviews',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Reviews.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dcourses',
    name: 'Dcourses',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Courses.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dhome',
    name: 'Dhome',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Home.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dcertificate',
    name: 'Dcertificate',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Certificate.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dnotification',
    name: 'Dnotification',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Notification.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dpayout',
    name: 'Dpayout',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Payout.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dprofile/:id',
    name: 'Dprofile',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Profile.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dallcourses/:cat',
    name: 'Dallcourses',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/Allcourses.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dstatements',
    name: 'Dstatements',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Statements.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dverification',
    name: 'Dverification',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Verification.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/DmanageAdmission/:id',
    name: 'DmanageAdmission',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/ManageAdmission.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/DmanageEmployment/:id',
    name: 'DmanageEmployment',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/ManageEmployment.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dearning',
    name: 'Dearning',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Earning.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Danalysis',
    name: 'Danalysis',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Analysis.vue'),
    meta: {
        requiresAuth: true
    }
}, {
    path: '/Dmessage/:id',
    name: 'Dmessage',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/dashboard/personal/Message.vue'),
    meta: {
        requiresAuth: true
    }
},



    //Super Admin routes
    {
        path: '/SARaffleItemCreate',
        name: 'SARaffleItemCreate',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/RaffleItemCreate.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, 
    {
        path: '/SARaffleItems',
        name: 'SARaffleItems',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/RaffleItems.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, 
    {
        path: '/SARaffleItemEdit/:id',
        name: 'SARaffleItemEdit',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/RaffleItemEdit.vue'),
        meta: {
            requiresSuperAuth: true
        }
    },
    {
        path: '/SARaffleItemAttempts',
        name: 'SARaffleItemAttempts',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/RaffleItems.vue'),
        meta: {
            requiresSuperAuth: true
        }
    },
    {
        path: '/SAhome',
        name: 'SAhome',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/Home.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, 
    {
        path: '/SAeditPost/:id',
        name: 'SAeditPost',
        component: () =>
            import( /* webpackChunkName: "Register" */ '../views/superAdmin/EditBlogPost.vue'),
        meta: {
            requiresSuperAuth: true
        }
    },
    {
        path: '/SAaddBlogPost',
        name: 'SAaddBlogPost',
        component: () =>
            import( /* webpackChunkName: "Register" */ '../views/superAdmin/AddBlogPost.vue'),
        meta: {
            requiresSuperAuth: true
        }
    },
    {
        path: '/SAblogPosts',
        name: 'SAblogPosts',
        component: () =>
            import( /* webpackChunkName: "Register" */ '../views/superAdmin/BlogPosts.vue'),
        meta: {
            requiresSuperAuth: true
        }
    },
    {
        path: '/SAinstructors',
        name: 'SAinstructors',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/Instructors.vue'),
        meta: {
            requiresSuperAuth: true
        }
    },
     {
        path: '/SAinfluencers',
        name: 'SAinfluencers',
        component: () =>
            import( /* webpackChunkName: "Register" */ '../views/superAdmin/Influencers.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAnewinstructors',
        name: 'SAnewinstructors',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/NewInstructors.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAinstructor/:id',
        name: 'SAinstructor/:id',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/Instructor.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAcourses',
        name: 'SAcourses',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/Courses.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAnewcourses',
        name: 'SAnewcourses',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/NewCourses.vue'),
        meta: {
            requiresSuperAuth: true
        }
    },
    {
        path: '/SAnewebooks',
        name: 'SAnewebooks',
        component: () =>
            import( /* webpackChunkName: "Register" */ '../views/superAdmin/NewEbooks.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAcourse/:id',
        name: 'SAcourse/:id',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/Course.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAinstitutions',
        name: 'SAinstitutions',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/Institutions.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAnewinstitutions',
        name: 'SAnewinstitutions',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/NewInstitutions.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAinstitution/:id',
        name: 'SAinstitution',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/Institution.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAnewinstitution',
        name: 'SAnewinstitution',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/NewInstitutions.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAaddNewSuperAdmin',
        name: 'SAaddNewSuperAdmin',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/AddNewSuperAdmin.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAhistoryPayouts',
        name: 'SAhistoryPayouts',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/HistoryPayouts.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SApurchasedCourses',
        name: 'SApurchasedCourses',
        component: () =>
            import( /* webpackChunkName: "Register" */ '../views/superAdmin/PurchasedCourses.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAbooks',
        name: 'SAbooks',
        component: () =>
            import( /* webpackChunkName: "Register" */ '../views/superAdmin/Books.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAbook/:id',
        name: 'SAbook',
        component: () =>
            import( /* webpackChunkName: "Register" */ '../views/superAdmin/Book.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAnewBooks',
        name: 'SAnewBooks',
        component: () =>
            import( /* webpackChunkName: "Register" */ '../views/superAdmin/NewBooks.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAsearchPayouts',
        name: 'SAsearchPayouts',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/SearchPayouts.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAnewPayouts',
        name: 'SAnewPayouts',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/NewPayouts.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAfeedbacks',
        name: 'SAfeedbacks',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/Feedbacks.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAupdateInstructors',
        name: 'SAupdateInstructors',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/UpdateInstructors.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAupdateInstitutions',
        name: 'SAupdateInstitutions',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/UpdateInstitutions.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAfeedback/:id',
        name: 'SAfeedback',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/Feedback.vue'),
        meta: {
            requiresSuperAuth: true
        }
    }, {
        path: '/SAlecturePage/:id',
        name: 'SAlecturePage',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/LecturePage.vue'),
        meta: {
            requiresSuperAuth: true
        }
    },
    {
        path: '/SAMessage/:id',
        name: 'SAMessage',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/Message.vue'),
        meta: {
            requiresSuperAuth: true
        }
    },
    {
        path: '/SAMessages',
        name: 'SAMessages',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/Messages.vue'),
        meta: {
            requiresSuperAuth: true
        }
    },
    {
        path: '/SAUsers',
        name: 'SAUsers',
        component: () =>
            import( /* webpackChunkName: "Register" */ '../views/superAdmin/Users.vue'),
        meta: {
            requiresSuperAuth: true
        }
    },
    {
        path: '/SAAdmins',
        name: 'SAAdmins',
        component: () =>
            import( /* webpackChunkName: "Register" */ '../views/superAdmin/Admins.vue'),
        meta: {
            requiresSuperAuth: true
        }
    },
    
    {
        path: '/SAlogin',
        name: 'SAlogin',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/superAdmin/Login.vue'),
        meta: {
            requiresSuperGuest: true
        }
    },
    //404 route
    {
        path: '/:catchAll(.*)',
        name: '404',
        component: () =>
            import ( /* webpackChunkName: "Register" */ '../views/site/404.vue')
    },
 {
    path: '/SAhistoryPayouts',
    name: 'SAhistoryPayouts',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/HistoryPayouts.vue'),
    meta: {
        requiresSuperAuth: true
    }
}, {
    path: '/SAsearchPayouts',
    name: 'SAsearchPayouts',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/SearchPayouts.vue'),
    meta: {
        requiresSuperAuth: true
    }
}, {
    path: '/SAnewPayouts',
    name: 'SAnewPayouts',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/NewPayouts.vue'),
    meta: {
        requiresSuperAuth: true
    }
}, {
    path: '/SAdonations',
    name: 'SAdonations',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/Donations.vue'),
    meta: {
        requiresSuperAuth: true
    }
}, {
    path: '/SAdonation/:id',
    name: 'SAdonation',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/Donation.vue'),
    meta: {
        requiresSuperAuth: true
    }
}, {
    path: '/SAfeedbacks',
    name: 'SAfeedbacks',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/Feedbacks.vue'),
    meta: {
        requiresSuperAuth: true
    }
}, {
    path: '/SAupdateInstructors',
    name: 'SAupdateInstructors',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/UpdateInstructors.vue'),
    meta: {
        requiresSuperAuth: true
    }
}, {
    path: '/SAupdateInstitutions',
    name: 'SAupdateInstitutions',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/UpdateInstitutions.vue'),
    meta: {
        requiresSuperAuth: true
    }
}, {
    path: '/SAfeedback/:id',
    name: 'SAfeedback',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/Feedback.vue'),
    meta: {
        requiresSuperAuth: true
    }
}, {
    path: '/SAlecturePage/:id',
    name: 'SAlecturePage',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/LecturePage.vue'),
    meta: {
        requiresSuperAuth: true
    }
},
{
    path: '/SAMessage/:id',
    name: 'SAMessage',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/Message.vue'),
    meta: {
        requiresSuperAuth: true
    }
},
{
    path: '/SAMessages',
    name: 'SAMessages',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/Messages.vue'),
    meta: {
        requiresSuperAuth: true
    }
},
{
    path: '/SAUsers',
    name: 'SAUsers',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/Users.vue'),
    meta: {
        requiresSuperAuth: true
    }
},
{
    path: '/SAAdmins',
    name: 'SAAdmins',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/Admins.vue'),
    meta: {
        requiresSuperAuth: true
    }
},

{
    path: '/SAlogin',
    name: 'SAlogin',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/Login.vue'),
    meta: {
        requiresSuperGuest: true
    }
},
{
    path: '/SACoupons',
    name: 'SACoupons',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/Coupons.vue'),
    meta: {
        requiresSuperAuth: true
    }
},
{
    path: '/SACoupon/:id',
    name: 'SACoupon',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/Coupon.vue'),
    meta: {
        requiresSuperAuth: true
    }
},
{
    path: '/SANewCoupon',
    name: 'SANewCoupon',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/superAdmin/NewCoupon.vue'),
    meta: {
        requiresSuperAuth: true
    }
},
//404 route
{
    path: '/:catchAll(.*)',
    name: '404',
    component: () =>
        import( /* webpackChunkName: "Register" */ '../views/site/404.vue')
}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.isLoggedIn) {
            localStorage.setItem('fmrlandingpage', window.location.href)
            // Redirect to login in
            next('/login');
        } else {
            next();
        }
    } else if (to.matched.some(record => record.meta.requiresGuest)) {
        if (store.getters.isLoggedIn) {
            // Redirect to login in
            next('/');
        } else {
            next();
        }
    } else {
        next()
    }


})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresSuperAuth)) {
        if (!store.getters.isSuperLoggedIn) {
            // Redirect to login in
            next('/SAlogin');
        } else {
            next();
        }
    } else if (to.matched.some(record => record.meta.requiresSuperGuest)) {
        if (store.getters.isSuperLoggedIn) {
            // Redirect to login in
            next('/SAhome');
        } else {
            next();
        }
    } else {
        next()
    }


})


export default router