<template>
  <div>
    <header class="header fadeInDown opacity" id="try">
      <div>
        <div id="logo">
          <a class="mr-2" href="/fast-her" v-if="$route.name==='GirlChild' || $route.name==='AboutGirlChild'"
            ><img
              src="../../assets/fast.png"
              width="180"
              height="50"
              alt=""
          /></a>
          <a href="/"
          v-else
            ><img
              src="../../assets/faslearnBlack.png"
              width="200"
              height="50"
              alt=""
          /></a>
          <a
            href="/search"
            class="d-none d-md-inline-block text-center"
            style="
              border: 2px solid;
              padding: 5px;
              border-radius: 15px;
              background: #fff;
              width: 120px;
            "
            >Courses</a
          >
         
        </div>
        <div class="my-auto"></div>
        <ul id="top_menu">
          <!-- <li class="d-none d-md-block float-left">
            <a href="/search"><div
              class="input-group mb-3 w-auto rounded-lg"
              style="
                color: #fff;
                border: 2px solid;
                width: 350px;
                border-color: #ef6421;
              "
            >
              <div class="input-group-prepend rounded-lg">
                <i
                  class="icon_search input-group-text"
                  style="
                    color: #fff;
                    background-color: #ef6421;
                    border-color: #ef6421;
                  "
                ></i>
              </div>
              <input
                type="text"
                class="form-control pl-3 rounded-lg border-orange"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
              />
            </div></a>
            
          </li> -->
          <li>
            <a
              href="/search"
              class="d-md-none d-sm-inline-block navButton text-center"
              >Courses</a
            >
          </li>
          <li
            class=""
            v-if="
              $route.name == 'Coursedetail' ||
              $route.name == 'Search' ||
              $route.name == 'Allcourses'
            "
          >
            <select
              class="rounded-lg"
              v-model="selectedCurrency"
              style="color: #fff; background-color: #ef6421; padding: 3px"
            >
              <option
                @click="getCurrency()"
                v-for="cur in currency"
                :key="cur"
                class="dropdown-item"
              >
                {{ cur }}
              </option>
            </select>
          </li>
          <li v-if="!isLoggedIn">
            <a href="/login" class="navButton"><i class="ti-user"></i></a>
          </li>
          <!-- <li v-if="!isLoggedIn">
            <a href="/register" class="navButton">Register</a>
          </li>
          -->
          <!-- <li>
            <a href="/search" class="search"><i class="icon_search"></i></a>
          </li> -->
          <!-- <div class="search-overlay-menu" style="ba">
                  <span class="search-overlay-close"><span class="closebt"><i class="ti-close"></i></span></span>
                  <form role="search" id="searchform" method="get">
                    <input value="" name="q" type="search" placeholder="Search..." />
                    <button type="submit"><i class="icon_search" style="color: "></i>
                    </button>
                  </form>
                </div> -->
          <li v-if="isLoggedIn">
            <a href="" @click.prevent="logOutUser" class="navButton"
              ><i class="ti-user"></i
            ></a>
          </li>
          <li v-if="isLoggedIn">
            <a href="/dashboard" class="navButton d-none d-md-inline-block">Dashboard</a>
            <a href="/dashboard" class="navButton d-md-none d-sm-inline-block"><i class="ti-layout-grid4"></i></a>
          </li>
          <!-- <li><a href="#0" class="search-overlay-menu-btn">Search</a></li>
                <li class="hidden_tablet"><a href="#0">Buy this template</a></li> -->
          <!-- <li class="hidden_tablet"><a href="/dashboard" class="btn_1 rounded">Admission</a></li> -->
          <li>
            <div class="hamburger hamburger--spin" id="handdii">
              <div class="hamburger-box">
                <div class="hamburger-inner"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <!-- /top_menu -->
    </header>
    <!-- /header -->
    <div id="main_menu1">
      <div class="search120 d-md-none">
        <router-link to="/search">
          <div class="ui search">
            <div class="ui left icon input swdh10">
              <input
                class="prompt srch10"
                type="text"
                placeholder="Search for Tuts Videos, Tutors, Tests and more.."
              />
              <i class="uil uil-search-alt icon icon1"></i>
            </div>
          </div>
        </router-link>
      </div>
      <br /><br />
      <div class="container1">
        <nav class="version_21" id="nav">
          <div class="row1">
            <div class="col-md-3">
              <router-link :to="'/allcourses/e'">
                <h3 style="color: black">Courses</h3>
              </router-link>
              <ul>
                <router-link
                  :to="'/allcourses/' + c._id"
                  v-for="c in categories"
                  :key="c"
                >
                  <li
                    style="
                      margin: 0px;
                      display: block;
                      font-size: 13px;
                      color: black;
                    "
                  >
                    {{ c.name }}
                  </li>
                </router-link>
              </ul>
            </div>
            <div class="col-md-3">
              <router-link :to="'/allteachers/e'">
                <h3 style="color: black">Teachers</h3>
              </router-link>
              <ul>
                <router-link
                  :to="'/allteachers/' + c._id"
                  v-for="c in categories"
                  :key="c"
                >
                  <li
                    style="
                      margin: 0px;
                      display: block;
                      font-size: 13px;
                      color: black;
                    "
                  >
                    {{ c.name }}
                  </li>
                </router-link>
              </ul>
            </div>
            <div class="col-md-3">
              <router-link :to="'/DregisterTeacher'">
                <h3 style="color: black">Become an Instructor</h3>
              </router-link>
            </div>
            <div class="col-md-3">
              <router-link :to="'/allinstitutes/all'">
                <h3 style="color: black">Institutes</h3>
              </router-link>
              <ul>
                <router-link :to="'/allinstitutes/University'">
                  <li
                    style="
                      margin: 0px;
                      display: block;
                      font-size: 13px;
                      color: black;
                    "
                  >
                    Universities
                  </li>
                </router-link>
                <router-link :to="'/allinstitutes/Corporate'">
                  <li
                    style="
                      margin: 0px;
                      display: block;
                      font-size: 13px;
                      color: black;
                    "
                  >
                    Corporate Org
                  </li>
                </router-link>
              </ul>
            </div>
            <div class="col-md-3">
              <h3 style="color: black">Extra pages</h3>
              <ul>
                <router-link :to="'/about'">
                  <li
                    style="
                      margin: 0px;
                      display: block;
                      font-size: 13px;
                      color: black;
                    "
                  >
                    About Faslearn
                  </li>
                </router-link>
                <router-link :to="'/faq'">
                  <li
                    style="
                      margin: 0px;
                      display: block;
                      font-size: 13px;
                      color: black;
                    "
                  >
                    FAQ
                  </li>
                </router-link>
                <router-link :to="'/terms'">
                  <li
                    style="
                      margin: 0px;
                      display: block;
                      font-size: 13px;
                      color: black;
                    "
                  >
                    Terms & Conditions
                  </li>
                </router-link>
                <!-- <router-link :to="'/contact'">
                            <li style="margin: 0px; display: block; font-size: 13px; color: black">Contact Us</li>
                            </router-link>
                            <router-link :to="'/blog'">
                            <li style="margin: 0px; display: block; font-size: 13px; color: black">Our Blog</li>
                            </router-link>
                            -->
              </ul>
            </div>
            <div class="col-md-3">
              <h3 style="color: black">Refer And Earn</h3>
              <ul>
                <router-link :to="'/affiliate'">
                  <li
                    style="
                      margin: 0px;
                      display: block;
                      font-size: 13px;
                      color: black;
                    "
                  >
                    Become An
                  </li>
                </router-link>
                <router-link :to="'/affiliate'">
                  <li
                    style="
                      margin: 0px;
                      display: block;
                      font-size: 13px;
                      color: black;
                    "
                  >
                    Affiliate
                  </li>
                </router-link>
              </ul>
            </div>
          </div>
          <!-- /row -->
        </nav>
        <div class="follow_us" style="padding: 10px; border-radius: 10px">
          <ul>
            <li style="color: black">Follow us</li>
            <li style="color: black">
              <a href="https://web.facebook.com/faslearn"
                ><i style="color: #ef6421" class="ti-facebook"></i
              ></a>
            </li>
            <!-- <li><a href="#0"><i class="ti-twitter-alt"></i></a></li>
                        <li><a href="#0"><i class="ti-google"></i></a></li>
                        <li><a href="#0"><i class="ti-pinterest"></i></a></li> -->
            <li style="color: black">
              <a
                href="https://www.instagram.com/invites/contact/?i=1hc6vtkr05fr1&utm_content=mamv4vg"
                ><i style="color: #ef6421" class="ti-instagram"></i
              ></a>
            </li>
            <li style="color: black">
              <a href="https://www.linkedin.com/company/faslearn"
                ><i style="color: #ef6421" class="ti-linkedin"></i
              ></a>
            </li>
          </ul>
        </div>
        {{ currencyRate }}
        <div class="follow_us" style="padding: 10px; border-radius: 10px">
          <h4 style="color: black">
            <a href="/DregisterTeacher" v-if="isLoggedIn">Become a Teacher</a>
          </h4>
        </div>
      </div>
    </div>
    <!-- /main_menu 2-->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Nav",
  data() {
    return {
      categories: "",
      selectedCurrency:'NGN',
      currency: ["NGN", "USD", "GBP"],
      currencyRate: ''
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapActions(["fetch_categories__public"]),
    logOutUser() {
      this.logout();
    },
    async getCurrency() {
      await fetch(
        `https://v6.exchangerate-api.com/v6/dccf5d54e3329a15ddfe6049/latest/NGN`
      )
        .then((res) => res.json())
        .then((data) => (this.currencyRate = data.conversion_rates[this.selectedCurrency]));
        this.$emit('getCurrency', this.currencyRate)
        this.$emit('selectedCurrency', this.selectedCurrency)
        
        
    },
  },
  mounted() {
    // this.getCurrency();
    window.scrollTo(0, 0);
    window.addEventListener("scroll", function () {
      if (window.scrollY > 1) {
        document.getElementById("try").classList.add("sticky");
      } else {
        document.getElementById("try").classList.remove("sticky");
      }
    });

    document.getElementById("handdii").addEventListener("click", function () {
      document.getElementById("main_menu1").classList.toggle("show");
      document.getElementById("try").classList.toggle("sticky_menu_active");
      document.getElementById("main_menu1").style.visibility = "visible";
    });

    this.fetch_categories__public().then((res) => {
      this.categories = res.data;
    });
  },
};
</script>
<style scoped>
/* @import url("./assets/css/style.css"); */
@import url("./assets/vendor/unicons-2.0.1/css/unicons.css");
@import url("./assets/css/night-mode.css");
@import url("./assets/vendor/fontawesome-free/css/all.min.css");
@import url("./assets/vendor/OwlCarousel/assets/owl.carousel.css");
@import url("./assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
@import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
@import url("./assets/vendor/semantic/semantic.min.css");
#main_menu1 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  min-height: 100vh;
  width: 100vw;
  -webkit-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  opacity: 1;
  display: none;
  background: rgb(228, 154, 118);
  background: -webkit-linear-gradient(left, white, rgb(238, 220, 211));
  background: linear-gradient(to right, white, rgb(238, 220, 211));
  overflow-y: scroll;
  padding-top: 120px;
}

@media (max-width: 767px) {
  #main_menu1 {
    padding-top: 90px;
  }
}
#main_menu1.show {
  display: block;
}
#main_menu1 nav {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
}
#main_menu1 nav ul {
  padding: 0;
  width: 100%;
  animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
}
#main_menu1 nav ul li {
  float: left;
  width: 40%;
  margin: 0 100px 30px 0;
}
@media (max-width: 1199px) {
  #main_menu1 nav ul li {
    margin: 0 30px 30px 0;
  }
}
@media (max-width: 575px) {
  #main_menu1 nav ul li {
    float: none;
    width: auto;
  }
}
#main_menu1 nav ul li a {
  position: relative;
  text-decoration: none;
  color: #fff;
  opacity: 0.7;
  font-weight: 500;
}
#main_menu1 nav ul li a:hover {
  opacity: 1;
}
#main_menu1 nav ul li a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
}

#main_menu1 nav ul li ul li {
  float: none;
  width: 100%;
  margin: 0 0 10px 0;
}
@media (max-width: 767px) {
  #main_menu1 nav ul li ul li {
    margin-bottom: 8px;
  }
}
#main_menu1 nav ul h3 {
  color: #fff;
  font-weight: 600;
  font-size: 21px;
  font-size: 1.3125rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
@media (max-width: 767px) {
  #main_menu1 nav ul h3 {
    margin-bottom: 10px;
    font-size: 16px;
    font-size: 1rem;
  }
}

.col-md-3 {
  display: inline-block;
  width: 300px;
}

.col-md-3 li {
  width: 300px;
  color: white;
}

/* .navButton{
  border: 2px solid white;
  color: #fff;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
} */

.navButton {
  /* border: 2px solid #EF6421; */
  color: white;
  padding: 13px;
  font-size: 12px;
  border-radius: 20px;
  background-color: #ef6421;
}

.hamburger-inner {
  background-color: #ef6421;
}

.hamburger-inner::after,
.hamburger-inner::before {
  background-color: #ef6421;
}

.opacity {
  background: rgba(243, 240, 237, 0.4);
}

.search {
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: #ef6421;
  font-size: 15px;
  color: white;
}
/* #logo a img{
  margin-top: -100px;
} */

@media (max-width: 900px) {
  /* .navButton{
    border: 2px solid white;
    padding-bottom: 20px;
    color: #fff;
    padding: 7px;
    font-size: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  } */
  .navButton {
    border: 2px solid #ef6421;
    padding-bottom: 20px;
    color: #ef6421;
    padding: 5px;
    font-size: 8px;
    margin-bottom: 10px;
    border-radius: 20px;
  }

  #top_menu {
    margin-top: -10px;
  }

  .search {
    padding: 5px;
    border: none;
    border-radius: 20px;
    background-color: #ef6421;
    font-size: 12px;
    color: white;
  }
}
</style>