<template>
  <div>
    <Nav @getCurrency="changeCurrency" @selectedCurrency="selectedCur" />

    <Slider />

    <div class="container margin_30_95">
      <div class="hosting_title erp_title text-center">
        <h2>FEATURED COURSES</h2>
        <p>
          Learn new skills and knowledge<br />
          Master what you already know. Choose from over 1000 online video
          courses with new additions published every month.
        </p>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-md-6 wow"
          data-wow-offset="150"
          v-for="cos in courses"
          :key="cos"
        >
          <router-link :to="'/coursedetail/' + cos._id">
            <a href="" class="grid_item">
              <figure
                class="block-reveal"
                style="height: 300px; border-radius: 5px"
              >
                <div class="block-horizzontal"></div>
                <CourseImage
                  :name="cos.thumbnailFile"
                  style="height: 200px; width: 100%"
                />
                <div class="info">
                  <Ratings :id="cos._id" :type="'course'" />
                  <b
                    ><h3
                      style="
                        font-size: 16px;
                        font-family: 'Poppins', sans-serif;
                      "
                    >
                      {{ cos.title }}
                    </h3></b
                  >
                  <div>
                    <small>{{ cos.category[0].name }}</small>
                  </div>

                  <div v-if="cos.price.$numberDecimal != 0">
                    <b v-if="rate">
                      <span v-if="selectedCurrency === 'USD'"
                        >${{
                          (cos.price.$numberDecimal * parseFloat(rate))
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                      <span v-if="selectedCurrency === 'NGN'"
                        >₦{{
                          (cos.price.$numberDecimal * parseFloat(rate))
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                      <span v-if="selectedCurrency === 'GBP'"
                        >£{{
                          (cos.price.$numberDecimal * parseFloat(rate))
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                    </b>
                    <b v-else
                      >₦{{
                        cos.price.$numberDecimal
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                      <span></span
                    ></b>

                    <small>
                      <Discount
                        :rate="rate"
                        :selectedCurrency="selectedCurrency"
                        :id="cos._id"
                        :price="cos.price.$numberDecimal"
                        :discount="cos.discountPercent"
                    /></small>
                  </div>
                  <div v-else>
                    <b>FREE</b>
                  </div>
                </div>
              </figure>
            </a>
          </router-link>
        </div>
      </div>
      <!-- /row -->
      <router-link :to="'/allcourses/e'">
        <div class="container">
          <p class="btn_home_align">
            <a href="" class="btn_1 rounded" style="background-color: #ef6421"
              >View all Courses</a
            >
          </p>
        </div>
      </router-link>
    </div>

    <div class="container margin_30_95">
      <div class="hosting_title erp_title text-center">
        <h2>FREE COURSES</h2>
        <p>
          Our commitment to your career progress remains at the core of our
          essence. Begin these free courses to launch and take you to the next
          level of your career.
        </p>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-md-6 wow"
          data-wow-offset="150"
          v-for="cos in freeCourses"
          :key="cos"
        >
          <router-link :to="'/coursedetail/' + cos._id">
            <a href="" class="grid_item">
              <figure
                class="block-reveal"
                style="height: 300px; border-radius: 5px"
              >
                <div class="block-horizzontal"></div>
                <CourseImage
                  :name="cos.thumbnailFile"
                  style="height: 200px; width: 100%"
                />
                <div class="info">
                  <Ratings :id="cos._id" :type="'course'" />
                  <b
                    ><h3
                      style="
                        font-size: 16px;
                        font-family: 'Poppins', sans-serif;
                      "
                    >
                      {{ cos.title }}
                    </h3></b
                  >
                  <div>
                    <small>{{ cos.category[0].name }}</small>
                  </div>

                  <div v-if="cos.price.$numberDecimal != 0">
                    <b v-if="rate">
                      <span v-if="selectedCurrency === 'USD'"
                        >${{
                          (cos.price.$numberDecimal * parseFloat(rate))
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                      <span v-if="selectedCurrency === 'NGN'"
                        >₦{{
                          (cos.price.$numberDecimal * parseFloat(rate))
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                      <span v-if="selectedCurrency === 'GBP'"
                        >£{{
                          (cos.price.$numberDecimal * parseFloat(rate))
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                    </b>
                    <b v-else
                      >₦{{
                        cos.price.$numberDecimal
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                      <span></span
                    ></b>

                    <small>
                      <Discount
                        :rate="rate"
                        :selectedCurrency="selectedCurrency"
                        :id="cos._id"
                        :price="cos.price.$numberDecimal"
                        :discount="cos.discountPercent"
                    /></small>
                  </div>
                  <div v-else>
                    <b>FREE</b>
                  </div>
                </div>
              </figure>
            </a>
          </router-link>
        </div>
      </div>
      <!-- /row -->
      <router-link :to="'/allcourses/free'">
        <div class="container">
          <p class="btn_home_align">
            <a href="" class="btn_1 rounded" style="background-color: #ef6421"
              >View all free Courses</a
            >
          </p>
        </div>
      </router-link>
    </div>
    <NewsLetter @close="close()" v-if="news" />

    <section style="background-color: #ef6421">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-lg-4 col-md-4">
            <div class="erp_content text-white">
              <h2>Fast- Her: <strong> Digital Literacy for Women. </strong></h2>
              <p class="text-md text-white">
                Empower underserved women near you with digital literacy skills
                to succeed in today's tech-driven world.
              </p>
              <router-link to="/fast-her">
                <a
                  class="btn_1 rounded mb-3"
                  style="background-color: white; color: #ef6421"
                  >Learn More</a
                >
              </router-link>
            </div>
          </div>
          <div class="col-lg-8 col-md-8">
            <img
              class="img-fluid"
              src="./assets/img/bannergirl.jpg"
              alt=""
              style="width: 100%"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="erp_analytics_area p-md-5">
      <div class="container">
        <div class="hosting_title erp_title text-center">
          <h2>WHY FASLEARN</h2>
          <p>
            Our team has carefully selected the best, most experienced and
            distinguished tutors in various fields. When you study any of our
            courses, you are getting more than it’s required for that skill.
          </p>
        </div>
        <div class="row software_featured_info">
          <div class="col-lg-4 col-sm-6">
            <div class="software_featured_item text-center mb_20">
              <div class="s_icon">
                <img src="./asset/img/home5/icon_shape.png" alt="" />
                <img class="icon" src="./asset/img/home5/icon1.png" alt="" />
              </div>
              <h3 class="f_600 t_color3">Wide range of courses</h3>
              <p class="f_400 f_size_15 mb-30">
                Explore a wide range of knowledge from numerous courses and
                learn whenever and wherever you choose.
              </p>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6">
            <div class="software_featured_item text-center mb_20">
              <div class="s_icon">
                <img src="./asset/img/home5/icon_shape.png" alt="" />
                <img class="icon" src="./asset/img/home5/icon2.png" alt="" />
              </div>
              <h3 class="f_600 t_color3">Learn with ease</h3>
              <p class="f_400 f_size_15 mb-30">
                We believe that there are no limits to learning and skill
                development, thus we offer high-end courses.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="software_featured_item text-center mb_20">
              <div class="s_icon">
                <img src="./asset/img/home5/icon_shape.png" alt="" />
                <img class="icon" src="./asset/img/home5/icon3.png" alt="" />
              </div>
              <h3 class="f_600 t_color3">Low cost</h3>
              <p class="f_400 f_size_15 mb-30">
                Whether you want to develop as a professional or you want to
                start a new career, we have affordable courses for your need.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Start -->
    <section class="section my-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 col-12 order-1 order-lg-2">
            <div
              class="section-title text-center text-lg-start mb-4 mb-lg-0 pb-2 pb-lg-0"
            >
              <span class="badge rounded-pill bg-soft-primary"
                >You can learn with
              </span>
              <h4 class="title mt-3 mb-4">FASLEARN AFFILIATED INSTITUTION</h4>
              <p class="text-muted para-desc mx-auto ms-lg-auto mb-0">
                We have partnered with distinguished and most sought after
                universities and learning institutions to give you university
                accredited certificates.
              </p>
              <router-link :to="'/allinstitutes/all'">
                <div class="container" style="margin-top: 20px">
                  <p>
                    <a
                      class="btn_1 rounded"
                      style="background-color: #ef6421; color: white"
                      >View all Institution</a
                    >
                  </p>
                </div>
              </router-link>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-7 col-12 order-2 order-lg-1">
            <div class="row me-lg-5">
              <div class="col-md-6 col-12">
                <div class="row">
                  <div
                    class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0"
                    v-if="allInstitutions.length > 0"
                  >
                    <div
                      class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow"
                    >
                      <div class="icons text-primary text-center mx-auto">
                        <img
                          :src="
                            publicFiles + allInstitutions[0].institutionLogoFile
                          "
                          style="
                            height: 100px;
                            width: 100px;
                            border-radius: 50%;
                          "
                          alt=""
                        />
                      </div>
                      <div class="card-body p-0 mt-4">
                        <router-link
                          :to="'/' + allInstitutions[0].handle"
                          v-if="allInstitutions[0].handle"
                          class="title h5 text-dark"
                          >{{ allInstitutions[0].institutionName }}
                        </router-link>
                        <router-link
                          v-else
                          :to="'/' + allInstitutions[0]._id"
                          class="title h5 text-dark"
                          >{{ allInstitutions[0].institutionName }}
                        </router-link>
                        <p style="font-size: 12px" class="text-muted mt-2 mb-0">
                          {{ allInstitutions[0].bio.substring(0, 100) }}...
                        </p>
                        <small class="text-muted mt-2 mb-0"
                          >@{{ allInstitutions[0].address }}</small
                        >
                        <i class="uil uil-rocket text-primary full-img"></i>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-12 mt-4 pt-2"
                    v-if="allInstitutions.length > 2"
                  >
                    <div
                      class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow"
                    >
                      <div class="icons text-primary text-center mx-auto">
                        <img
                          :src="
                            publicFiles + allInstitutions[2].institutionLogoFile
                          "
                          style="
                            height: 100px;
                            width: 100px;
                            border-radius: 50%;
                          "
                          alt=""
                        />
                      </div>
                      <div class="card-body p-0 mt-4">
                        <router-link
                          :to="'/' + allInstitutions[2].handle"
                          v-if="allInstitutions[2].handle"
                          class="title h5 text-dark"
                          >{{ allInstitutions[2].institutionName }}
                        </router-link>
                        <router-link
                          :to="'/' + allInstitutions[2]._id"
                          v-else
                          class="title h5 text-dark"
                          >{{ allInstitutions[2].institutionName }}
                        </router-link>
                        <p style="font-size: 12px" class="text-muted mt-2 mb-0">
                          {{ allInstitutions[2].bio.substring(0, 100) }}...
                        </p>
                        <small class="text-muted mt-2 mb-0"
                          >@{{ allInstitutions[2].address }}</small
                        >
                        <i class="uil uil-rocket text-primary full-img"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div
                  class="row pt-lg-4 mt-lg-4"
                  v-if="allInstitutions.length > 1"
                >
                  <div class="col-12 mt-4 pt-2">
                    <div
                      class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow"
                    >
                      <div class="icons text-primary text-center mx-auto">
                        <img
                          :src="
                            publicFiles + allInstitutions[1].institutionLogoFile
                          "
                          style="
                            height: 100px;
                            width: 100px;
                            border-radius: 50%;
                          "
                          alt=""
                        />
                      </div>
                      <div class="card-body p-0 mt-4">
                        <router-link
                          :to="'/' + allInstitutions[1].handle"
                          v-if="allInstitutions[1].handle"
                          class="title h5 text-dark"
                          >{{ allInstitutions[1].institutionName }}</router-link
                        >
                        <router-link
                          :to="'/' + allInstitutions[1]._id"
                          v-else
                          class="title h5 text-dark"
                          >{{ allInstitutions[1].institutionName }}</router-link
                        >
                        <p style="font-size: 12px" class="text-muted mt-2 mb-0">
                          {{ allInstitutions[1].bio.substring(0, 100) }}...
                        </p>
                        <small class="text-muted mt-2 mb-0"
                          >@{{ allInstitutions[1].address }}</small
                        >
                        <i class="uil uil-rocket text-primary full-img"></i>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-12 mt-4 pt-2"
                    v-if="allInstitutions.length > 3"
                  >
                    <div
                      class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow"
                    >
                      <div class="icons text-primary text-center mx-auto">
                        <img
                          :src="
                            publicFiles + allInstitutions[3].institutionLogoFile
                          "
                          style="
                            height: 100px;
                            width: 100px;
                            border-radius: 50%;
                          "
                          alt=""
                        />
                      </div>
                      <div class="card-body p-0 mt-4">
                        <router-link
                          :to="'/' + allInstitutions[3].handle"
                          v-if="allInstitutions[3].handle"
                          class="title h5 text-dark"
                          >{{ allInstitutions[3].institutionName }}</router-link
                        >
                        <router-link
                          :to="'/' + allInstitutions[3]._id"
                          v-else
                          class="title h5 text-dark"
                          >{{ allInstitutions[3].institutionName }}</router-link
                        >
                        <p style="font-size: 12px" class="text-muted mt-2 mb-0">
                          {{ allInstitutions[3].bio.substring(0, 100) }}...
                        </p>
                        <small class="text-muted mt-2 mb-0"
                          >@{{ allInstitutions[3].address }}</small
                        >
                        <i class="uil uil-rocket text-primary full-img"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end Row-->

            <div class="mt-4 d-lg-none d-block text-center">
              <router-link to="/login" class="btn btn-primary"
                >See more</router-link
              >
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- End -->

    <section>
      <div class="bg_color_1">
        <div class="container margin_60_35">
          <div class="sec_title mb_70">
            <h2 class="f_p f_size_24 l_height28 f_700 t_color3 mb_20">
              FEATURES
            </h2>
            <p class="f_400 f_size_15">
              Here are some unique and more affordable ways to take advantage of
              FASLEARN:-
            </p>
          </div>
          <div class="row">
            <div class="col-md-4">
              <a href="/Dexplore" class="boxed_list">
                <div class="s_icon">
                  <img src="./asset/img/demo/icon/community.png" alt="" />
                </div>
                <br />
                <h4>Faslearn Memberships</h4>
                <p>
                  Pay less when you register a group of Learners! <br />
                  In summary, the more the merrier!
                </p>
              </a>
            </div>
            <div class="col-md-4">
              <a href="/DregisterTeacher" class="boxed_list">
                <div class="s_icon">
                  <img src="./asset/img/demo/icon/live_chat.png" alt="" />
                </div>
                <br />
                <h4>Faslearn For Companies</h4>
                <p>
                  You can join other companies and large teams to access our
                  courses at a discounted rate.
                </p>
                <!-- With Faslearn, the more students you enrol to take courses, the lesser the course fees! -->
              </a>
            </div>
            <div class="col-md-4">
              <a href="/DregisterInstitude" class="boxed_list">
                <div class="s_icon">
                  <img src="./asset/img/demo/icon/email.png" alt="" />
                </div>
                <br />
                <h4>Faslearn For Large Teams</h4>
                <p>
                  Start your group learning and monitor progress of each student
                  from your dashboard!
                </p>
              </a>
            </div>
          </div>
          <!-- /row -->
        </div>
        <!-- /container -->
      </div>
    </section>

    <section class="erp_action_area" style="background-color: #ef6421">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-3 col-md-4">
            <img src="./asset/img/erp-home/action_img.png" alt="" />
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="erp_content text-white">
              <h2>Enjoy a great students community</h2>
              <p class="text-md text-white">
                After taking a course with us, we don’t just leave you all by
                yourself. We have an active community of students and tutors
                that will answer your questions and give you all the support you
                need. You will also have first hand access to job opportunities
                and resources as we have partnered with reputable job placement
                organizations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container mt-5 mx-auto">
      <div class="row">
        <span class="h2 my-5 text-center"
          >What People Think About Faslearn</span
        >
        <div class="test-u owl-theme">
          <div class="test-scroll scroll">
            <div class="d-flex flex-row">
              <div
                class="card mx-1"
                style="
                  min-height: 250px;
                  width: 650px;
                  height: 260px;
                  padding: auto;
                  max-height: 260px;
                "
                v-for="user in userRating"
                :key="user.name"
              >
                <div class="d-flex flex-row justify-content-around">
                  <img
                    class="img-thumbnail img-fluid"
                    style="height: 80px; width: 80px; border-radius: 45px"
                    :src="user.img"
                    :alt="user.alt"
                  />
                  <span class="my-auto">
                    <h5 class="card-title">{{ user.name }}</h5>
                    <div class="" style="color: #f8b704">
                      <i
                        class="icon_star"
                        :class="{ voted: avrating > 0 && avrating >= 1 }"
                      ></i>

                      <i
                        class="icon_star"
                        :class="{ voted: avrating > 1 && avrating >= 2 }"
                      ></i>

                      <i
                        class="icon_star"
                        :class="{ voted: avrating > 2 && avrating >= 3 }"
                      ></i>

                      <i
                        class="icon_star"
                        :class="{ voted: avrating > 3 && avrating >= 4 }"
                      ></i>
                      <i
                        class="icon_star-half_alt"
                        :class="{ voted: avrating > 3 && avrating < 4 }"
                        v-if="avrating < 4"
                      ></i>

                      <i
                        class="icon_star"
                        :class="{ voted: avrating > 4 && avrating == 5 }"
                        v-if="!(avrating > 4 && avrating < 5)"
                      ></i>
                      <i
                        class="icon_star-half_alt"
                        :class="{ voted: avrating > 4 && avrating < 5 }"
                        v-if="avrating > 4 && avrating < 5"
                      ></i>
                      {{ avrating }}
                    </div>
                  </span>
                </div>
                <div class="">
                  <div class="card-body">
                    <p class="card-text">
                      {{ user.comment }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="experts_team_area sec_pad">
      <div class="container">
        <div class="sec_title text-center mb_70">
          <h2 class="f_p f_size_30 t_color3 mb_20">
            Become a FASLEARN AFFILIATE MARKETING PARTNER
          </h2>
          <p class="f_400 f_size_16">
            We have partnered with distinguished and most sought after
            universities and learning institutions to give you university
            accredited certificates.
          </p>
        </div>
        <div class="bg_color_1">
          <div class="container margin_120_95">
            <div class="row justify-content-center">
              <div class="">
                <div>
                  <img
                    src="./assets/img/Earnfaslearn.png"
                    alt=""
                    class="img-thumbnail mx-auto img-fluid rounded"
                    width="800"
                  />
                </div>
              </div>
            </div>
            <div class="mt-2">
              <router-link :to="'/affiliate'">
                <p class="btn_home_align">
                  <a
                    href=""
                    class="btn_1 rounded"
                    style="background-color: #ef6421"
                    >Become an Affiliate</a
                  >
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>
 
<script>
// @ is an alias to /src hope u knw
//import Help from '@/components/site/Help.vue'
import Discount from "@/components/Discount";
import CourseImage from "@/components/CourseImage";
// import ProfileImage from "@/components/ProfileImage";
import Ratings from "@/components/site/Ratings.vue";
import { mapActions, mapGetters } from "vuex";
import Nav from "@/components/site/Nav.vue";
import NewsLetter from "@/components/site/NewsLetter.vue";
import Footer from "@/components/site/Footer.vue";
import Slider from "@/components/site/Slider.vue";

export default {
  name: "Home",
  data() {
    return {
      courses: "",
      freeCourses: [],
      allInstitutions: "",
      all_Instructors: "",
      teachers: "",
      news: false,
      rate: null,
      selectedCurrency: "",
      userRating: [
        {
          name: "Nneka Uloaku Nwagu",
          comment:
            "I’ve been attending their webinars and it’s been explosive and they share so much knowledge. They are so committed to the growth of their online students. I got their excel course and it is worth the cost. Thinking of getting their data analysis course soon. I encourage you to get on their platform, join their community and buy their courses. Totally worth it.",
          img: "https://lh3.googleusercontent.com/a-/ACNPEu8MECa_B5fuVwTVFL45dAm45ix_0bdgerwHaoAAYQ=w60-h60-p-rp-mo-br100",
        },
        {
          name: "Preshy dope",
          comment:
            "Their platform is very friendly and my learning experience was good. The fact that I can access the course anytime is what makes me very happy. The courses are affordable also.",
          img: "https://lh3.googleusercontent.com/a/ALm5wu2BX-eT06khVaRnlRnJYXRnnSjNi5dS8QnJOAZG=w60-h60-p-rp-mo-br100",
          alt: "P",
        },
        {
          name: "Justice Ukpong",
          comment:
            "Calling Faslearn the Udemy of Africa is evident by the excellence the platform brings to fore. Learning has been easy and fun with Faslearn and you can be sure to gain value for your money the very moment you hop on the courses on this platform.",
          img: "https://lh3.googleusercontent.com/a-/ACNPEu8_TLAax1vqlyc-Gj5CR7e4XGTJk8-Zk_v8c12uNg=w60-h60-p-rp-mo-br100",
        },
        {
          name: "NAFIU “OSAM” SHITTU",
          comment:
            "Fantastic learning platform. If you want to learn at your pace without burning your pocket, then you need Faslearn. Don't say I didn't do anything for you o. Try their Project Management training programmes first.",
          img: "https://lh3.googleusercontent.com/a-/ACNPEu8BryBYIEq4Wr9kNSLQOKZ2Yi2acSPDl6A_JM7S=w60-h60-p-rp-mo-br100",
        },
        {
          name: "Adenomor Omoyokan Moses",
          comment:
            "Great platform, the courses here are really up to standard. I must say it is a good value for money",
          img: "https://blaqdevs.netlify.app/_nuxt/img/blaqdevs.8ec54f8.jpeg,",
        },
        {
          name: "Lolli’s Kitchen",
          comment:
            "I took a course from faslearn and I must say their platform offers extremely affordable courses with recognised certificates etc..Good job….I will recommend to whom ever needs it",
          img: "https://lh3.googleusercontent.com/a-/ACNPEu8rMqTEJ2ea7GwBXg2dHtu06d5oKTakKZDzSXovCQ=w60-h60-p-rp-mo-br100",
        },
        {
          name: "Joseph Olusegun",
          comment:
            "I sincerely appreciate the time and effort you put into creating this platform. a top-tier e-learning platform that steers the field of distance learning.",
          img: "https://lh3.googleusercontent.com/a-/ACNPEu96vl0MqojW2ZdImdzKkPp1Ymswuru3MpfPH1hs5Q=w60-h60-p-rp-mo-br100",
        },
        {
          name: "Victoria",
          comment:
            "The courses are well explained, it is very easy for students to assimilate what they learn on the platform",
          img: "https://lh5.googleusercontent.com/p/AF1QipNxao717J0aroN-SrgNSxxN6FgeBsp_ts5Q4Ixk=w600-h988-p-k-no",
        },
        {
          name: "Cosimo Michael",
          comment: "This is the most amazing place to study and acquire skills",
          img: "https://lh3.googleusercontent.com/a/ALm5wu2BT7s8kZ4maKByaoqlFfvNdonB_ypxACDgVMdG=w60-h60-p-rp-mo-br100",
        },
      ],
    };
  },
  components: {
    Nav,
    Ratings,
    NewsLetter,
    // ProfileImage,
    Slider,
    CourseImage,
    //Help,
    Footer,
    Discount,
  },

  watch: {
    $route() {
      if (this.$route.query.k === "i") {
        this.$router.push("https://faslearn.com/allcourses/e");
      }
    },
  },

  computed: {
    ...mapGetters(["backEnd"]),
    ...mapGetters(["publicFiles"]),
  },
  methods: {
    ...mapActions([
      "fetch_recent_courses__public",
      "fetchAllCategoryCoursesPagination__public",
    ]),
    ...mapActions(["fetchAllInstructors__public"]),
    ...mapActions(["fetch_all_institutions__public"]),
    close() {
      this.news = false;
    },
    changeCurrency(rate) {
      this.rate = rate;
    },
    selectedCur(cur) {
      this.selectedCurrency = cur;
    },
  },
  mounted() {
    if (this.$route.query.analytics === "true") {
      // alert('it changed')
      // window.location.href = 'https://faslearn.com/allcourses/e'
      this.$router.push("/allcourses/e");
    }

    setTimeout(() => {
      this.news = true;
    }, 1000000);

    this.fetch_recent_courses__public(0).then((res) => {
      this.courses = res.data;
      this.courses.pop();
    });

    this.fetchAllCategoryCoursesPagination__public({
      cat: "free",
      page: 1,
    }).then((res) => {
      for (let i = 0; i < 3; i++) {
        const element = res.data.courses[i];
        this.freeCourses.push(element);
      }
    });

    this.fetch_all_institutions__public({ skip: 0, type: "all" }).then(
      (res) => {
        this.allInstitutions = res.data;
      }
    );

    this.fetchAllInstructors__public(0)
      .then((res) => {
        this.teachers = res.data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  },
};
</script> 

<style scoped>
@import url("./asset/css/bootstrap.min.css");
@import url("./asset/vendors/animation/animate.css");
@import url("./asset/css/style.css");
@import url("./asset/css/responsive.css");
@import url("./assets/css/style.css");
@import url("./assets/assets/vendor/OwlCarousel/assets/owl.carousel.css");
@import url("./assets/assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
@import url("./assets/css/vendors.css");
@import url("./assets/css/wizard.css");
@import url("./assets/css/icon_fonts/css/all_icons.min.css");

.test-scroll {
  min-height: 200px;
  min-width: 1500px;
}
.test-u {
  overflow: hidden;
}
.featured_courses .item {
  width: 350px;
  display: inline-block;
  margin-left: 10px;
}

::-webkit-scrollbar {
  width: 20px;
}
@media (max-width: 575.98px) {
  .test-u {
    overflow-x: scroll;
  }
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}
.item_img {
  width: 100%;
  min-height: 300px;
}

.background {
  background-color: grey;
}

.biggie {
  height: 300px;
  width: 100%;
  overflow-y: hidden;
  overflow: hidden;
  margin-bottom: 20px;
}

.scroll {
  height: 100%;
  width: 3100px;
  animation-name: slideTeacher;
  /* animation-delay: 5s; */
  animation-duration: 40s;
  animation-iteration-count: infinite;
}

.teachers {
  display: inline-block;
  height: 100%;
  width: 200px;
  margin-left: 50px;
}

@keyframes slideTeacher {
  0% {
    margin-left: 0px;
  }
  15% {
    margin-left: -300px;
  }
  30% {
    margin-left: -600px;
  }
  45% {
    margin-left: -900px;
  }
  60% {
    margin-left: -1200px;
  }
  75% {
    margin-left: -1500px;
  }
  90% {
    margin-left: -1800px;
  }
  95% {
    margin-left: 10px;
  }
  100% {
    margin-left: 0px;
  }
}

.mainbackground {
  animation-name: mychangeslid2;
  animation-duration: 40s;
  animation-iteration-count: infinite;
}

@keyframes mychangeslid2 {
  0% {
    background-image: url(../../assets/11.jpg) center center no-repeat;
    background-size: 200%;
  }
  15% {
    background-image: url(../../assets/11.jpg) center center no-repeat;
    background-size: 200%;
  }
  25% {
    background-image: url(../../assets/9.jpg) center center no-repeat;
    background-size: 200%;
  }
  40% {
    background-image: url(../../assets/9.jpg) center center no-repeat;
    background-size: 200%;
  }
  50% {
    background-image: url(../../assets/8.jpg) center center no-repeat;
    background-size: 200%;
  }
  65% {
    background-image: url(../../assets/8.jpg) center center no-repeat;
    background-size: 200%;
  }
  75% {
    background-image: url(../../assets/4.jpg) center center no-repeat;
    background-size: 200%;
  }
  85% {
    background-image: url(../../assets/4.jpg) center center no-repeat;
    background-size: 200%;
  }
  90% {
    background-image: url(../../assets/7.jpg) center center no-repeat;
    background-size: 200%;
  }
  100% {
    background-image: url(../../assets/7.jpg) center center no-repeat;
    background-size: 200%;
  }
}
</style>