<template>
<div>
 <div v-if="rate">
    <span v-if="selectedCurrency=='USD'"><s>${{(discountPrice*parseFloat(rate)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</s> {{discountPercent}}% discount </span>
    <span v-if="selectedCurrency=='GBP'"><s>£{{(discountPrice*parseFloat(rate)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</s> {{discountPercent}}% discount </span>
    <span v-if="selectedCurrency=='NGN'"><s>₦{{(discountPrice*parseFloat(rate)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</s> {{discountPercent}}% discount </span>
    </div>
    <span v-if="!rate"><s>₦{{discountPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</s> {{discountPercent}}% discount </span>   
</div>

</template>
<script>
export default {
    data(){
        return{
            discountPrice: 0,
            discountPercent: 0
        }
    },
    props: {
        id: String,
        price: Number,
        discount: Number,
        selectedCurrency: String,
        rate: Number
    },
    methods: {
        useDummyDiscounts(){
            var splitted = this.id.split("");
            var total = 1
            for (let i = 0; i < splitted.length; i++) {
                const element = splitted[i];
                if (this.checkCharacter(element)) {
                    total++
                }
            }
            return total
        },
        checkCharacter(character){
            var stringflag = false
            var value = /^\d+$/.test(character)
            if (!value){
                stringflag = true
            }
            if (stringflag) {
                return false
            }else{
                return true
            }
        },
    },
    mounted(){
        let discount = this.discount
        this.discountPercent = this.discount
        let price = this.price
        if (discount == null|| discount == ''||discount == 'undefined') {
            discount = this.useDummyDiscounts()
            this.discountPercent = discount
        }
        this.discountPrice =  ~~((discount/100)*price)+ parseFloat(price)
    }
}
</script>
<style >

</style>