<template>
    <div style="height: 100%; width: 100%">
        <img src="./assets/add_img.jpg" alt="" v-if="name==null||name==''" style="height: 100%; width: 100%">
        <img v-bind:src="publicFiles+name" alt="" v-else style="height: 100%; width: 100%">
    </div> 
</template>
<script> 
import { mapGetters } from "vuex";
export default {
    name: 'ProfileImage',
    data(){
        return{
            isPhoto: true
        }
    },
	props: {
		id: String,
        name: String,
    },
    computed: {
        ...mapGetters(['publicFiles'])
    }
	
}
</script>
<style >
</style>