<template>
  <div>
    <!-- the old chat -->
    <!-- <div class="fixed-btn d-none d-md-block">
      <router-link to="/chat">
        <img
          style="
            width: ;
            height: 70px;
            position: fixed;
            bottom: 30%;
            right: 3%;
            z-index: 10000;
          "
          id="logo"
          class="img-responsive"
          src="../assets/chat.png"
          alt="logo"
        />
      </router-link>
    </div>
    <div class="fixed-btn d-sm-none">
                <router-link
                    to="/chat">
                    <img style="
                      width: ;
                      height: 70px;
                      position: fixed;
                      bottom: 30%;
                      right: 3%;
                      z-index: 10000;
                    " id="logo" class="img-responsive" src="../assets/chat-small.png" alt="logo" />
                </router-link>
                    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>