<template>
  <section>
    <div class="card bg-dark text-white" v-for="i in [currentIndex]" :key="i">
      <img class="card-img" :src="currentImg" style="object-fit:cover"  />
      <div class="card-img-overlay">
        <div
        class="container mt-5 pt-5"
      >
        <div class="row mt-md-5">
          <div class="col-lg-6 d-flex align-items-center" style="background: #000; opacity: 0.7; border-radius: 5px">
            <div
              class="software_banner_content text-md-left text-center"
              style="padding: 20px; border-radius: 10px"
            >
              <h2
                class="f_500 f_size_50 w_color wow fadeInLeft"
                data-wow-delay="0.2s"
                style="
                  visibility: visible;
                  animation-delay: 0.2s;
                  animation-name: fadeInLeft;
                "
              >
                {{currentheading}}
              </h2>
              <p
                class="w_color f_size_18 l_height30 mt_30 wow fadeInLeft"
                data-wow-delay="0.4s"
                style="
                  visibility: visible;
                  animation-delay: 0.4s;
                  animation-name: fadeInLeft;
                "
              >
                {{currentlabel}}
              </p>
              <div
                class="
                  action_btn
                  d-flex
                  align-items-center
                  mt_40
                  wow
                  fadeInLeft
                "
                data-wow-delay="0.6s"
                style="
                  visibility: visible;
                  animation-delay: 0.6s;
                  animation-name: fadeInLeft;
                "
              >
                <a href="/allcourses/e" class="software_banner_btn">Get Started</a>
                <!-- <a href="https://www.youtube.com/watch?v=sU3FkzUKHXU" class="video_btn popup-youtube">
                                <div class="icon"><i class="ti-control-play"></i></div><span>Watch Video</span>
                            </a> -->
              </div>
            </div>
          </div>
          <div class="col-lg-6 d-none d-lg-block">
            <div
              class="software_img wow fadeInRight"
              data-wow-delay="0.2s"
              style="
                visibility: visible;
                animation-delay: 0.2s;
                animation-name: fadeInRight;
              "
            >
              <!-- <img src="../../views/site/asset/img/home5/banner_img.png" alt="" /> -->
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Slider",
  data() {
    return {
      images: [
        require("../../assets/1.jpg"),
        require("../../assets/2.jpg"),
        require("../../assets/3.jpg"),
        require("../../assets/4.jpg"),
        require("../../assets/5.jpg"),
        require("../../assets/6.jpg"),
        require("../../assets/7.jpg"),
        require("../../assets/8.jpg"),
        require("../../assets/9.jpg"),
        require("../../assets/11.jpg"),
        require("../../assets/12.jpg")
      ],
      labels: [
        "Unlock your potential and shape a successful future through quality learning.",
        'We believe that high-quality education should be accessible to all. Enjoy the ease of getting a certificate from reputable universities without the stress of being physical',
        "Tech-powered, in-depth courses for autonomous learning,  With direct access to your course instructor and support services 24/7.",
        "Our learning technique combines the convenience of online learning with the advantages of face-to-face education, making it suitable for the present condition of digital work.",
        "With over 60 professional courses and certifications, advancing, switching, and starting your career just got easier. Choose from a wide range of online video courses with new additions published every week.",
        "Unlock your potential and shape a successful future through quality learning.",
        'We believe that high-quality education should be accessible to all. Enjoy the ease of getting a certificate from reputable universities without the stress of being physical',
        "Tech-powered, in-depth courses for autonomous learning,  With direct access to your course instructor and support services 24/7.",
        "Our learning technique combines the convenience of online learning with the advantages of face-to-face education, making it suitable for the present condition of digital work.",
        "With over 60 professional courses and certifications, advancing, switching, and starting your career just got easier. Choose from a wide range of online video courses with new additions published every week.",
        "Tech-powered, in-depth courses for autonomous learning,  With direct access to your course instructor and support services 24/7.",
       
      ],
      headings: [
        "FASLEARN YOUR FUTURE",
        "AFFORDABLE ONLINE LEARNING",
        "LEARN ANYTIME, ANYWHERE",
        "Online Learning - THE EASIER WAY",
        "Learn Without Limits",
        "FASLEARN YOUR FUTURE",
        "AFFORDABLE ONLINE LEARNING",
        "LEARN ANYTIME, ANYWHERE",
        "Online Learning - THE EASIER WAY",
        "Learn Without Limits",
        "LEARN ANYTIME, ANYWHERE",
        
      ],
      timer: null,
      currentIndex: 0,
    };
  },

  mounted: function () {
    this.startSlide();
  },

  methods: {
    startSlide: function () {
      this.timer = setInterval(this.next, 15000);
    },

    next: function () {
      this.currentIndex += 1;
    },
    prev: function () {
      this.currentIndex -= 1;
    },
  },

  computed: {
    currentImg: function () {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    },
    currentlabel: function () {
      return this.labels[Math.abs(this.currentIndex) % this.labels.length];
    },
    currentheading: function () {
      return this.headings[Math.abs(this.currentIndex) % this.headings.length];
    }
  },
};
</script>


<style scoped>


img {
  height: 700px;
  width: 100%;
}

</style>