<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <keep-alive :key="$route.fullPath">
  <router-view/>
  </keep-alive>
</template>
<script>
import { mapActions } from "vuex";



export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions(["addCart"]),
    ...mapActions(["save_purchased_course", "save_purchased_bundle", "save_purchased_book", "saveDonation", "saveOutletSubsciption"]),
    ...mapActions(["refreshENV"]),

  },
  mounted() {
    let URL = window.location.href + "";
    const myAffArr = URL.split("affiliateUserRef=");
    if (myAffArr.length > 1) {
      if (myAffArr[1] != null && myAffArr[1] != "") {
        localStorage.setItem("fmrAffiliateUserRef", myAffArr[1]);
        console.log(myAffArr[1]);
      }
    }

    let ref = localStorage.getItem("fmrlastreference");
    var cart;
    if (ref == "" || ref == null) {
      console.log("something");
    } else {
      var success = false;
      cart = JSON.parse(localStorage.getItem("fmrcart"));
      let object = {
        reference: ref,
        userId: localStorage.getItem("fmruser"),
        courseId: cart.id,
        membershipId: cart.membershipId,
        price: cart.price,
      };
      this.save_purchased_course(object)
        .then((res) => {
          console.log(res);
          console.log("worked");
          success = true;
          localStorage.setItem("fmrcart", "");
          localStorage.setItem("fmrlastreference", "");
          window.location.reload();
        })
        .catch({
          success: false,
        });
      if (success) {
        localStorage.setItem("fmrcart", "");
        localStorage.setItem("fmrlastreference", "");
      }

    }

    let refbundle = localStorage.getItem("fmrlastbundlereference");
    var cartbundle;
    if (refbundle != "" && refbundle != null && refbundle != undefined) {
      success = false;
      cartbundle = JSON.parse(localStorage.getItem("fmrbundlecart"));
      let object = {
        reference: refbundle,
        userId: localStorage.getItem("fmruser"),
        bundleId: cartbundle.id,
        membershipId: cartbundle.membershipId,
        price: cartbundle.price,
      };
      this.save_purchased_bundle(object)
        .then((res) => {
          console.log(res);
          console.log("worked");
          success = true;
          localStorage.removeItem("fmrbundlecart");
          localStorage.removeItem("fmrlastbundlereference");
          window.location.reload();
        })
        .catch({
          success: false,
        });
        if (success) {
          localStorage.removeItem("fmrbundlecart");
          localStorage.removeItem("fmrlastbundlereference");
        }
    }

    let refbook = localStorage.getItem("fmrlastbookreference");
    var cartbook;
    if (refbook != "" && refbook != null && refbook != undefined) {
      success = false;
      cartbook = JSON.parse(localStorage.getItem("fmrbookcart"));
      let object = {
        reference: refbook,
        userId: localStorage.getItem("fmruser"),
        bookId: cartbook.id,
        membershipId: cartbook.membershipId,
        price: cartbook.price,
      };
      this.save_purchased_book(object)
        .then((res) => {
          console.log(res);
          console.log("worked");
          success = true;
          localStorage.removeItem("fmrbookcart");
          localStorage.removeItem("fmrlastbookreference");
          window.location.reload();
        })
        .catch({
          success: false,
        });
        if (success) {
          localStorage.removeItem("fmrbookcart");
          localStorage.removeItem("fmrlastbookreference");
        }
    }

    let refDonation = localStorage.getItem("fmrDonationLastReference");
    var cartDonation;
    if (refDonation != "" && refDonation != null && refDonation != undefined) {
      success = false;
      cartDonation = JSON.parse(localStorage.getItem("fmrDonation"));
      let object = {
        reference: refDonation,
        price: cartDonation.price,
        email: cartDonation.email,
        firstName: cartDonation.firstName,
        lastName: cartDonation.lastName,
        phone: cartDonation.phone,
        totalStudents: cartDonation.totalStudents,
        course: cartDonation.course
      };
      this.saveDonation(object)
        .then((res) => {
          if (res.data.status) {
            console.log("worked");
            success = true;
            localStorage.removeItem("fmrDonation");
            localStorage.removeItem("fmrDonationLastReference");
          } else {
            
            success = false;
          }
          
        })
        .catch({
          success: false,
        });
        // if (success) {
        //   localStorage.removeItem("fmrDonation");
        //   localStorage.removeItem("fmrDonationLastReference");
        // }
    }

    let refOutlet = localStorage.getItem("fmrlastoutletreference");
    var cartOutlet;
    if (refOutlet != "" && refOutlet != null && refOutlet != undefined) {
      success = false;
      cartOutlet = JSON.parse(localStorage.getItem("fmroutletcart"));
      let object = {
        reference: refOutlet,
        userId: localStorage.getItem('fmruser'),
        outletId: cartOutlet.id,
        price: cartOutlet.price,
        subscribed_months: cartOutlet.subscribed_months
      };
      this.saveOutletSubsciption(object)
        .then((res) => {
          if (res.data.status) {
            console.log("worked");
            success = true;
            localStorage.removeItem("fmroutletcart");
            localStorage.removeItem("fmrlastoutletreference");
          } else {
            success = false;
          }
        })
        .catch({
          success: false,
        });
    }

    this.refreshENV();

    // if (ref == '' || ref == null) {
    //   console.log('something')
    // }else{
    //   var success = true
    //   cart = JSON.parse(localStorage.getItem('fmrcart'));
    //   for (let index = 0; index < cart.length; index++) {
    //     const item = cart[index];
    //     let object = {
    //       reference : ref,
    //       userId: localStorage.getItem('fmruser'),
    //       courseId : item._id
    //     }

    //     this.save_purchased_course(object)
    //     .then(res =>{
    //       console.log(res)
    //       console.log('worked')
    //     })
    //     .catch({
    //       success : false
    //     })

    //   }
    //   if (success) {
    //     localStorage.setItem("fmrcart", '');
    //     localStorage.setItem("fmrlastreference", '');
    //     this.addCart(0)
    //   }
    //   //console.log(ref)
    // }

    // let cart = []
    // cart = localStorage.getItem('fmrcart')
    // if (cart == '' || cart == null) {
    // 	cart = [];
    // }else{
    //   cart = JSON.parse(localStorage.getItem('fmrcart'));
    // 	this.addCart(cart.length);
    // }
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* //text-align: center; */
  font-size: 15px;
  color: #2c3e50;
}

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
