<template>
  <!-- Button trigger modal -->
  <div class="modal-backdrop">
    <div class="card" >
      <div id="form" class="card mx-auto" style="border: #000 2px 2px solid; padding:10px; ">
        
        <div class="card-header text-center">
          <h2>Subscribe To Our Newsletter</h2>
        </div>
        <div class="card-body">
          <form
            action="https://fmragency.com/sendy/subscribe"
            method="POST"
            accept-charset="utf-8"
          >
            <label for="name">Name</label><br />
            <input
              placeholder="Enter Your Name"
              class="form-control mb-2"
              type="text"
              name="name"
              id="name"
            />
            <br />
            <label for="email">Email</label><br />
            <input
              type="email"
              placeholder="Email"
              class="form-control mb-2"
              name="email"
              id="email"
            /><br />
            <div style="display: none">
              <label for="hp">HP</label><br />
              <input type="text" name="hp" id="hp" />
            </div>
            <input type="hidden" name="list" value="ilYyUJzz8gh3QCKnZL2nkQ" />
            <input type="hidden" name="subform" value="yes" />
            <input
              type="submit"
              name="submit"
              value="Sign Up Now"
              style="background: #C46233; border: #C46233"
              class="btn btn-primary mb-2"
              id="submit"
            />
          </form>
        </div>
      <button class="bg-orange" @click="close">close</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
#form {
    align-content: flex-start;
  margin: 20px auto;
  width: 700px;
}
input{
  border: 1px solid #000;
}
</style>