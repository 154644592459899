<template>
    <div class="rating">
        <i class="icon_star" :class="{ voted: avrating>0&&avrating>=1 }" v-if="avrating>=1"></i>
        <i class="icon_star-half_alt" :class="{ voted: avrating>0&&avrating<1 }" v-if="avrating<1"></i>

        <i class="icon_star" :class="{ voted: avrating>1&&avrating>=2 }" v-if="avrating>=2"></i>
        <i class="icon_star-half_alt" :class="{ voted: avrating>1&&avrating<2 }" v-if="avrating<2"></i>

        <i class="icon_star" :class="{ voted: avrating>2&&avrating>=3 }" v-if="avrating>=3"></i>
        <i class="icon_star-half_alt" :class="{ voted: avrating>2&&avrating<3 }" v-if="avrating<3"></i>

        <i class="icon_star" :class="{ voted: avrating>3&&avrating>=4 }" v-if="avrating>=4"></i>
        <i class="icon_star-half_alt" :class="{ voted: avrating>3&&avrating<4 }" v-if="avrating<4"></i>

        <i class="icon_star" :class="{ voted: avrating>4&&avrating==5 }" v-if="!(avrating>4&&avrating<5)"></i>
        <i class="icon_star-half_alt" :class="{ voted: avrating>4&&avrating<5 }" v-if="avrating>4&&avrating<5"></i>
        {{avrating}}
        <br>
        
        <small>  Based on {{starsNumber}} reviews (<i class="icon_like"></i> {{allLikesNumber}})</small>
    </div>										
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: 'Rating',
	props: {
        id: String,
        type: String,
        like: Boolean
    },
    data(){
		return{
            alreadyReviewed: true,
            reviews: '',
            stars: '',
            starsNumber: '',
            avrating: '',
            allLikes: '',
            allLikesNumber: '',
        }
    },
    methods: {
		...mapActions(['fetchReviewedPublic']),
        checkAveageStar(){
            let total = 0;
            let answer = 0;
            if (this.stars.length == 0) {
                answer = 0;
            }else{
                for (let i = 0; i < this.stars.length; i++) {
                    const element = this.stars[i];
                    total = element.star + total
                }
                answer = total/this.stars.length;
            }
            return answer.toFixed(1);
        },
        countlikes(){
            let total = { like : 0, dislike : 0};
            for (let i = 0; i < this.stars.length; i++) {
                const element = this.stars[i];
                if (element.like == 1) {
                    total.like++
                }else{
                    total.dislike++
                }
            }
            return total;
        },
        fetchingReviews(type){
            let reviewObject= { 
				id : this.id,
				type : type
			}
			this.fetchReviewedPublic(reviewObject)
			.then(res => {
				this.alreadyReviewed = res.data.alreadyReviewed
				this.reviews = res.data.Reviews
                this.stars = res.data.stars
                this.starsNumber = this.stars.length
                this.avrating = this.checkAveageStar()
                this.allLikes = this.countlikes()
                this.allLikesNumber = this.allLikes.like
                if (this.avrating==0) {
                    this.useDummyRatings()
                }
			})
        },
        useDummyRatings(){
            var splitted = this.id.split("");
            var totalRatings = 1
            var totalLikes = 1
            for (let i = 0; i < splitted.length; i++) {
                const element = splitted[i];
                if (this.checkCharacter(element)) {
                    totalLikes++
                    if (i<=9) {
                        totalRatings++
                    }
                }
                
            }
            this.avrating = ((60 + (totalRatings*3))/20).toFixed(1)
            this.starsNumber = totalLikes*6
            this.allLikesNumber = ((this.starsNumber/2)+(this.starsNumber/4)).toFixed(0)
            if (this.avrating>=4.0) {
              this.allLikesNumber = ((this.starsNumber/2)+(this.starsNumber/4)+(this.starsNumber/8)).toFixed(0)  
            }
        },
        checkCharacter(character){
            var stringflag = false
            var value = /^\d+$/.test(character)
            if (!value){
                stringflag = true
            }
            if (stringflag) {
                return false
            }else{
                return true
            }
        },
    },
    mounted(){
        this.fetchingReviews(this.type)
    }
}
</script>
<style >

</style>