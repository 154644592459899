import axios from 'axios';
//import router from '../../router';


const state = {
    superLoader : '',
    error: '',
    SALoad: 0
};

const getters = {
    superLoader: state => state.superLoader,
    SALoad: state => state.SALoad,
};

const actions = {
    async add_blogPost({commit}, blogData) {
        try {
            commit('fetch_super_request');
            let res = await axios.post('superAdmin/createBlogPost__Admin', blogData);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 
    async add_SuperAdmin({
        commit
    }, adminData) {
        try {
            commit('fetch_super_request');
            let res = await axios.post('superAdmin/addSuperAdmin', adminData);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 
    async fetch_SuperDasboard({
        commit
    }) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchSuperDasboard');
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async fetctinstructors__Admin({
        commit
    }, skip) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/instructors/'+ skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 
    async fetctusers__Admin({
        commit
    }, skip) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/users/' + skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async calculateUserPayouts__Admin({
        commit
    }, id) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/SA/calculateUserPayouts/' + id);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 
    async fetchHistoryPayouts__Admin({
        commit
    }, skip) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchHistoryPayouts/' + skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 
    async fetctPurchasedCourses__Admin({
        commit
    }, skip) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetctPurchasedCourses__Admin/' + skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async fetchNewPayouts__Admin({
        commit
    }, skip) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchNewPayouts/' + skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async markPaidPayout__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.post('superAdmin/markPaidPayout', object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 
    async fetchFeedbacks__Admin({
        commit
    }, skip) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchFeedbacks/'+ skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async viewBook__Admin({
        commit
    }, bookId) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/viewBook__Admin/' + bookId);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async fetchAllUnseenBooks__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchAllUnseenBooks__Admin/' + object.type + '/' + object.skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async fetchAllSeenBooks__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchAllSeenBooks__Admin/' + object.type + '/' +object.skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async fetchInstructorChangeRequest___Admin({
        commit
    }, skip) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchInstructorChangeRequest__Admin/' + skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async fetchInstitutionChangeRequest___Admin({
        commit
    }, skip) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchInstitutionChangeRequest__Admin/' + skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async fetchUnseenUnverifiedInstitution_Admin({
        commit
    }, skip) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchUnseenUnverifiedInstitution_Admin/' + skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async fetchSeenVerifiedInstitution_Admin({
        commit
    }, skip) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchSeenVerifiedInstitution_Admin/' + skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async fetchSeenUnverifiedInstitution_Admin({
        commit
    }, skip) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchSeenUnverifiedInstitution_Admin/' + skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async fetchUnseenVerifiedInstitution_Admin({
        commit
    }, skip) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchUnseenVerifiedInstitution_Admin/' + skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async fetchSingleInstitution_Admin({
        commit
    }, id) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchSingleInstitution_Admin/' + id);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async viewInstitutionImg_Admin({
        commit
    }, id,type) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/viewInstitutionImg_Admin/' + id +'/' + type);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 
    async manageVerifyInstitution_Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.post('superAdmin/manageVerifyInstitution_Admin', object );
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async fetchAllUnseenInstructor__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchAllUnseenInstructor__Admin/' + object.type + '/' + object.skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async fetchAllSeenInstructor__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchAllSeenInstructor__Admin/' + object.type + '/' + object.skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async fetchInstructorDetails__Admin({
        commit
    }, id) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchInstructorDetails__Admin/' + id);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async fetchAllUnseenCourses__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchAllUnseenCourses__Admin/' + object.type + '/' + object.skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async fetchAllSeenCourses__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchAllSeenCourses__Admin/' + object.type + '/' + object.skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async viewCourse__Admin({
        commit
    }, courseId) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/viewCourse__Admin/' + courseId );
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async manageVerifyInstructor_Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.post('superAdmin/manageVerifyInstructor_Admin', object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async AddSubAccountPaystack_Admin({
        commit
    }, object) {
        try {
            let key = "sk_live_3f03ce409664f67e8e2f6b6df4d895a3a6757035"
            commit('fetch_super_request');
            let res = await axios.post('https://api.paystack.co/subaccount', object, {
                headers: {
                    'Authorization': `Bearer ${key}`
                }
            });
            commit('fetch_super_success');
            
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async manageVerifyCourse_Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.post('superAdmin/manageVerifyCourse_Admin', object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 
    async manageVerifyBook_Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.post('superAdmin/manageVerifyBook_Admin', object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 
    async fetchLecture__Admin({
        commit
    }, lectureId) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchLecture__Admin/' + lectureId);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 
    async fetchSingleFeedback__Admin({
        commit
    }, feedbackId) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchSingleFeedback__Admin/' + feedbackId);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 
    async markSeenFeedback_Admin({
        commit
    }, feedbackId) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/markSeenFeedback_Admin/' + feedbackId);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 
    async fetchOnePublicMessage__Admin({
        commit
    }, type) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchOnePublicMessage__Admin/' + type);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 
    async fetchAllPublicMessages__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchAllPublicMessages__Admin/' +object.type+'/'+object.skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    async updatePublicMessage__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/updatePublicMessage__Admin/' +object.id+'/'+object.type);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async fetchVerifiedUsers__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchVerifiedUsers__Admin/' + object.isVerified + '/' + object.page + '/' + object.limit + object.slug );
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async countNewPublicMessages__Admin({
        commit
    }, type) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/countNewPublicMessages__Admin/' + type);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 
    async changeCourseOwner_Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.post('superAdmin/changeCourseOwner__Admin', object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    
    async fetchInflencerInstructors__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchInflencerInstructors__Admin/'+object.type+'/'+object.skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 

    async changeInfluencerStatus__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.post('superAdmin/changeInfluencerStatus__Admin', object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async deleteSuperAdmin__Admin({
        commit
    }, id) {
        try {
            commit('fetch_super_request');
            let res = await axios.delete('superAdmin/deleteSuperAdmin__Admin/' + id);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async editSuperAdmin__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.put('superAdmin/editSuperAdmin__Admin/'+ object.id, object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async fetchAllSuperAdmin__Admin({
        commit
    }) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchAllSuperAdmin__Admin');
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async fetchBlogPost__Admin({
        commit
    }, id) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchBlogPost__Admin/' +id );
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async deleteBlogPost__Admin({
        commit
    }, id) {
        try {
            commit('fetch_super_request');
            let res = await axios.delete('superAdmin/deleteBlogPost__Admin/' + id);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async editBlogPost__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.post('superAdmin/editBlogPost__Admin', object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 

    async publishBlogPost__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.post('superAdmin/publishBlogPost__Admin', object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async deleteBlogPostContent__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.delete('superAdmin/deleteBlogPostContent__Admin/' + object.id + "/" + object.content_id);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    
    async createBlogPostContent__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.post('superAdmin/createBlogPostContent__Admin', object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async editBlogPostContent__Admi({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.post('superAdmin/editBlogPostContent__Admi', object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async fetchBlogPosts__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchBlogPosts__Admin/' + object.page + "/" + object.limit + "/" + object.published);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async getCoupons__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/getCoupons__Admin/' + object.type + '/' + object.skip );
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async getCoupon__Admin({
        commit
    }, id) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/getCoupon__Admin/' + id);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async getCourseCoupons__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/getCourseCoupons__Admin/' + object.id + "/" + object.skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async fetchDonation__Admin({
        commit
    }, id) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchDonation__Admin/' + id);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async fetchDonations__Admin({
        commit
    }, skip) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/fetchDonations__Admin/' + skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async checkMemberShipMax__Admin({
        commit
    }, id) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/checkMemberShipMax__Admin/' + id);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async manageMembership__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.post('superAdmin/manageMembership__Admin', object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async createCoupon__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.post('superAdmin/createCoupon__Admin',  object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async deleteCoupon__Admin({
        commit
    }, id) {
        try {
            commit('fetch_super_request');
            let res = await axios.delete('superAdmin/deleteCoupon__Admin/' + id);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async createRaffleDrawItems__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.post('superAdmin/createRaffleDrawItems__Admin',  object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async raffleDrawItemDelivered__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.patch('superAdmin/raffleDrawItemDelivered__Admin',  object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async raffleDrawItemRecieved__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.patch('superAdmin/raffleDrawItemRecieved__Admin',  object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async updateRaffleDrawItem__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.patch('superAdmin/updateRaffleDrawItem__Admin',  object);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async getRaffleDrawItems__Admin({
        commit
    }, slug) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/getRaffleDrawItems__Admin'+ slug);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    }, 

    async getRaffleDrawAttempts__Admin({
        commit
    }, object) {
        try {
            commit('fetch_super_request');
            let res = await axios.get('superAdmin/getRaffleDrawAttempts__Admin/' + object.id + '/' + object.skip);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },

    async deleteRaffleDrawItems__Admin({
        commit
    }, id) {
        try {
            commit('fetch_super_request');
            let res = await axios.delete('superAdmin/deleteRaffleDrawItems__Admin/' + id);
            commit('fetch_super_success');
            return res;
        } catch (err) {
            commit('fetch_super_error', err)
        }
    },
    
    async SALoad_refresh({
        commit
    }) {
        commit('SALoad_refresh');
    },

};

const mutations = {
    SALoad_refresh(state) {
        state.SALoad = 0
    },

    fetch_super_request(state) {
        state.error = null
        state.superLoader = 'loading'
        state.SALoad++
    },
    fetch_super_success(state) {
        state.error = null
        state.superLoader = 'success'
        state.SALoad--
    },
    fetch_super_error(state, err) {
        state.superLoader = 'error'
        state.error = err.response.data
        state.SALoad--
    },
    
};

export default {
    state,
    actions,
    mutations,
    getters
}